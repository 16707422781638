import React, { useState, useRef, lazy } from "react";
import "./App.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import AOS from "aos";
import "aos/dist/aos.css";
import {
  FaLinkedinIn,
  FaInstagram,
  FaStackOverflow,
  FaFacebookF,
} from "react-icons/fa";
// import Loader from "./common/Loader/Loader";

const Section1 = lazy(() => import("./Section1/Section1"));
const Section2 = lazy(() => import("./Section2/Section2"));
const Section3 = lazy(() => import("./Section3/Section3"));
const Section4 = lazy(() => import("./Section4/Section4"));
const Section5 = lazy(() => import("./Section5/Section5"));
const Section6 = lazy(() => import("./Section6/Section6"));
const Section8 = lazy(() => import("./Section8/Section8"));

function App() {
  // const [loader, setLoader] = useState(true);
  const workref = useRef();

  // useEffect(() => {
  //   const handleLoad = () => {
  //     setLoader(false);
  //   };
  //   window.addEventListener("load", handleLoad);
  //   return () => {
  //     window.removeEventListener("load", handleLoad);
  //   };
  // }, []);

  useState(() => {
    AOS.init({ disable: false, once: true });
  }, []);

  return (
    <div className="App">
      {/* {loader && (
        <div>
          <Loader />
          <div
            style={{ zIndex: 50 }}
            className="position-absolute vw-100 vh-100 align-items-center justify-content-center d-flex bg-light"
          >
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      )} */}
      <Section1
        onWorkClick={() =>
          workref.current.scrollIntoView({ behavior: "smooth" })
        }
      />
      <Section2 />
      <Section4 />
      <Section3 />
      <div ref={workref}>
        <Section5 />
      </div>
      <Section6 />
      {/* <Section7 /> */}
      <Section8 />
      <footer>
        <div className="container-fluid">
          <div className="row justify-content-center align-items-center footer flex-column p-5">
            <div className="col-lg-2 col-md-4 col-7 d-flex justify-content-between px-4">
              <a
                href="https://in.linkedin.com/in/mehthab-musambil-199912162"
                className="social-icons"
                target="blank"
              >
                <FaLinkedinIn className="text-white-50" />
              </a>
              <a
                href="https://www.instagram.com/mehthab_musambil/?hl=en"
                target="blank"
                className="social-icons"
              >
                <FaInstagram className="text-white-50 social-icons" />
              </a>
              <a
                href="https://stackoverflow.com/users/11879582/mehthab-musambil"
                target="blank"
                className="social-icons"
              >
                <FaStackOverflow className="text-white-50 social-icons" />
              </a>
              <a
                href="https://www.facebook.com/mehthab.musammil"
                target="blank"
                className="social-icons"
              >
                <FaFacebookF className="text-white-50 social-icons" />
              </a>
            </div>
            <div className="col-md-10 col-12 mt-1">
              <p className="text-white text-white-50 mt-3 text-center">
                © 2020 Mehthab Musambil. All Rights Reserved | Designed by{" "}
                <span className="text-white"> MEFx Studio</span>
              </p>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
